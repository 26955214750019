import React from 'react'
import Moment from 'react-moment'
import MainHeading from '@/components/MainHeading'
import RoundedLink from '@/components/RoundedLink'

interface PostHeaderData {
  heading?: string
  date?: string
  personName?: string
  personText?: string
  personImage?: any
  authorString?: string
  archiveUrl: string
  archiveString: string
}

const PostHeader = ({
  heading,
  date,
  personName,
  personText,
  personImage,
  archiveUrl,
  archiveString = 'Kaikki uutiset',
  authorString = 'Kirjoittaja',
}: PostHeaderData) => {
  return (
    <div className="post-header my-10 grid lg:grid-cols-2 gap-10">
      <div>
        <p className="mb-4 text-2xl">
          <Moment format="DD.MM.YYYY" date={date} />
        </p>
        <MainHeading className="mb-2 wordbreak">{heading}</MainHeading>

        {personName && (
          <p>
            {authorString}: {personName}
          </p>
        )}
        {archiveUrl && (
          <RoundedLink
            className="mt-6 border-black text-black hover:text-white focus:text-white hover:bg-black focus:bg-black"
            to={archiveUrl}
          >
            {archiveString}
          </RoundedLink>
        )}
      </div>
      {personText || personImage ? (
        <div className="bg-lighterlatte w-full md:w-2/3 flex items-center lg:w-screen">
          <div className="p-5 sm:p-10 w-full flex-col sm:flex-row flex items-center flex-shrink-0 xl:max-w-postperson lg:max-w-postpersonxs">
            {personImage && (
              <div className="mb-5 sm:mb-0 sm:mr-10 w-2/4 sm:w-1/3 flex-shrink-0 rounded-full overflow-hidden object-cover">
                <div
                  style={{ backgroundImage: `url(${personImage})` }}
                  className="rounded-full bg-no-repeat bg-cover bg-center person-image"
                ></div>
              </div>
            )}
            <div>
              <div className="break-words">{personText}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default PostHeader
