import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import PostHeader from '@/components/PostHeader'
import Wysiwyg from '@/components/Wysiwyg'
import Gutenberg from '@/components/Gutenberg'
import Seo from '@/components/Seo'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { seotype } from '@/types.d'
import { getArhiveUrl } from '@/functions'
import RoundedLink from '@/components/RoundedLink'

interface DefaultPostData {
  data: {
    form: any
    post: {
      featuredImage: any
      title: string
      date: string
      gwPostAuthorName: string
      gwPostAuthorContent: string
      gwPostAuthorImage: string
      content: string
      gwPostFooterHeading: string
      gwPostFooterContent: string
      translations: []
      language: {
        slug: string
      }
      uri: string
      seo: seotype
    }
    mainMenu: any
    langData: any
    wp: any
  }
}

const SinglePostTemplate = ({ data }: DefaultPostData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(post.language.slug)
  }, [])

  const { post } = data
  const { form } = data
  const { mainMenu } = data
  const { wp } = data

  const langData = { languages: wp.languages, currentLang: post.language.slug, translations: post.translations }
  const arhiveurl = getArhiveUrl(post?.language?.slug, wp.archiveurl)

  return (
    <Layout featuredImage={post.featuredImage} nav={mainMenu} lang={langData} form={form}>
      {post.seo && (
        <Seo
          title={post?.seo?.title}
          description={post?.seo?.metaDesc}
          keywords={post?.seo?.metaKeyword}
          pathname={post?.uri}
          lang={post?.language?.slug}
          image={post?.featuredImage?.node?.localFile?.url}
          twitterTitle={post?.seo?.twitterTitle}
          twitterDescription={post?.seo?.twitterDescription}
          opengraphTitle={post?.seo?.opengraphTitle}
          opengraphDescription={post?.seo?.opengraphDescription}
          opengraphImage={post?.seo?.opengraphImage?.localFile?.url}
          twitterImage={post?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container>
        <PostHeader
          heading={post.title}
          date={post.date}
          personName={post.gwPostAuthorName}
          personText={post.gwPostAuthorContent}
          personImage={post.gwPostAuthorImage}
          authorString={t('Kirjoittaja')}
          archiveString={t('Siirry uutiset sivulle')}
          archiveUrl={arhiveurl}
        />
        <Gutenberg>{post.content}</Gutenberg>
      </Container>
      {post.gwPostFooterHeading || post.gwPostFooterContent ? (
        <Container className="bg-lighterlatte -mb-14">
          <div className="flex items-center justify-center py-20">
            <div className="post-footer max-w-3xl w-full">
              <h2 className="mb-4">{post.gwPostFooterHeading}</h2>
              <Wysiwyg className="xs:pl-20 w-full">{post.gwPostFooterContent}</Wysiwyg>
            </div>
          </div>
          {arhiveurl && (
            <div className="flex justify-center items-center pb-20">
              <RoundedLink
                className="border-black text-black hover:text-white focus:text-white hover:bg-black focus:bg-black"
                to={arhiveurl}
              >
                {t('Siirry uutiset sivulle')}
              </RoundedLink>
            </div>
          )}
        </Container>
      ) : null}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostById($id: String!, $menu: [WpMenuLocationEnum], $translang: String, $formNum: Int) {
    post: wpGwPost(id: { eq: $id }) {
      id
      title
      uri
      date
      content
      gwPostFooterContent
      gwPostFooterHeading
      gwPostAuthorContent
      gwPostAuthorImage
      gwPostAuthorName
      seo {
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            url
          }
        }
        twitterImage {
          localFile {
            url
          }
        }
      }
      ...featuredHeroImagePost
      ...langdatapost
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
      archiveurl: crbThemeOptions {
        archiveEn: gwPostPageUrlen
        archiveFi: gwPostPageUrlfi
        archiveSv: gwPostPageUrlsv
      }
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default SinglePostTemplate
